import React from "react";
import "./Footer.css";
import { BsGithub, BsGooglePlay, BsInstagram } from "react-icons/bs";
import { FiMail } from "react-icons/fi";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div id="home" className="footer_container">
      <h1 className="footer_title">SerenAI</h1>
      <h2 className="footer_subtitle">Your AI-Based Mental Health Companion</h2>
      <div className="social-icons">
        <a
          href="https://play.google.com/store/apps/dev?id=6458947373351124430&hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsGooglePlay size={20} />
        </a>
        <a
          href="https://www.instagram.com/ser_en.ai/?hl=en"
          target="_blank"
          rel="noopener noreferrer"
        >
          <BsInstagram size={20} />
        </a>
        <a
          href="mailto:hello@serenai.in"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FiMail size={20} />
        </a>
      </div>
      <div className="footer_links">
        <a href="/terms-and-conditions" target="blank" className="footer_link">
          Terms and Conditions
        </a>
        <a href="/privacy-policy" target="blank" className="footer_link">
          Privacy Policy
        </a>
      </div>
      <p>© 2023 Seren Innovations. All rights reserved.</p>
    </div>
  );
};

export default Footer;
