import React from "react";
import "./Contact.css";
import emailjs from "emailjs-com";
import { useRef } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Contact = () => {
  const form = useRef();
  const [formStatus, setFormStatus] = React.useState("Send Message");
  const onSubmit = (e) => {
    e.preventDefault();
    setFormStatus("Submitting...");
    console.log(e.target);
    emailjs
      .sendForm(
        "service_uz5uuwa",
        "template_a9c6xw7",
        form.current,
        "oBquSnHdXTOgv_KP9"
      )
      .then(
        (result) => {
          console.log(result);
          setFormStatus("Submitted");
        },
        (error) => {
          console.log(error.text);
          setFormStatus("Could not Submit");
        }
      );
  };
  return (
    <AnimationOnScroll duration={0.4} animateIn="animate__fadeIn">
      <div className="container contact_container" id="contact">
        <div className="contact-form">
          <h2>Contact Us</h2>
          <a href="mailto:hello@serenai.in" className="subtitle">
            Mail Us : hello@serenai.in
          </a>
          <form ref={form} onSubmit={onSubmit}>
            <div>
              <label className="form-label" htmlFor="name">
                Name
              </label>
              <input
                placeholder="Enter Name"
                name="from_name"
                className="form-control"
                type="text"
                id="name"
                required
              />
            </div>
            <div>
              <label className="form-label" htmlFor="email">
                Email
              </label>
              <input
                placeholder="Enter Email"
                className="form-control"
                type="email"
                id="email"
                required
              />
            </div>
            <div>
              <label className="form-label" htmlFor="message">
                Message
              </label>
              <textarea
                placeholder="Type Your Message..."
                name="message"
                className="form-control"
                id="message"
                required
              />
            </div>
            <button className="white-btn" id="send" type="submit">
              {formStatus}
            </button>
          </form>
        </div>
        <div className="contact-img"></div>
      </div>
    </AnimationOnScroll>
  );
};

export default Contact;
