import React from "react";
import "./Projects.css";
import ProjectComponent from "./ProjectComponent";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Projects = () => {
  const project = {
    title: "Features",

    description:
      "SerenAI offers a unique mental health companion experience through its app, providing users with the opportunity to chat with an AI companion anytime, anywhere. Whether you're dealing with depression, stress, PTSD, anxiety, loneliness, or just need someone to talk to, SerenAI has a wide range of topics to support you. Each session is time-limited but can be continued later at your convenience. Users can also review a summary of their session and provide feedback, ensuring a personalized and evolving support experience.",
  };

  return (
    <AnimationOnScroll duration={0.4} animateIn="animate__fadeIn">
      <div id="projects" className="container projects_container">
        <center>
          <h2>SerenAI: Your Personal Mental Health Companion</h2>
        </center>
        <div className="individual-project-container">
          <ProjectComponent
            pid={0}
            title={project.title}
            desc={project.description}
          />
        </div>
      </div>
    </AnimationOnScroll>
  );
};

export default Projects;
