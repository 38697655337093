import React from "react";
import "./About.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

class About extends React.Component {
  render() {
    return (
      <AnimationOnScroll duration={0.4} animateIn="animate__fadeIn">
        <section id="about" className="container about_container">
          <div className="about-dp" />
          <div className="about-txt">
            <h2>About</h2>
            <br />
            <br />
            Seren Innovations is a pioneering company dedicated to advancing
            mental health support through the power of artificial intelligence.
            Our flagship product, Seren, is an AI-based mental health companion
            that provides 24/7 support, offering personalized and empathetic
            responses to individuals facing mental health challenges.
            <br />
            <br />
            By integrating cutting-edge technology with evidence-based
            therapeutic practices like Cognitive Behavioral Therapy (CBT), we
            aim to make mental health resources accessible, reliable, and
            user-friendly. At Seren Innovations, our mission is to empower
            individuals on their journey to mental well-being, ensuring they
            always have a supportive companion at their side.
          </div>
        </section>
      </AnimationOnScroll>
    );
  }
}

export default About;
