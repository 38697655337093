import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container container">
      <center>
        <h1 style={{ marginBottom: 10, marginTop: 20 }}>Privacy Policy</h1>
      </center>
      <br />
      <br />

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>Introduction</h2>
        <p>
          SerenAI is a chatbot application designed to offer conversational
          companionship to those seeking mental health support. We use OpenAI's
          API (Application Programming Interface) to enhance our chatbot's
          responses, providing a personalized and effective experience.
          Protecting your privacy and personal data is our priority. This
          Privacy Policy details the types of data we collect, how we use and
          store it, and your rights regarding your data.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>
          Types of Data We Collect
        </h2>
        <p>We collect the following categories of data:</p>
        <ul>
          <li>
            <strong>Personal Information</strong>: Information that identifies
            you, such as your email address.
          </li>
          <li>
            <strong>Usage Data</strong>: Information about your interactions
            with the SerenAI app, including usage times, discussion topics, and
            responses to prompts.
          </li>
          <li>
            <strong>Technical Data</strong>: Details about your device,
            including device type, operating system, and IP address.
          </li>
        </ul>
        <p>OpenAI may also collect:</p>
        <ul>
          <li>
            <strong>Prompt and Completion Pairs</strong>: The prompts you input
            and the responses generated by the chatbot.
          </li>
          <li>
            <strong>Embeddings</strong>: Mathematical representations of the
            text you provide and the chatbot’s responses.
          </li>
          <li>
            <strong>Training and Validation Data</strong>: Data used by OpenAI
            to train and refine its models.
          </li>
        </ul>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>
          How We Use Your Data
        </h2>
        <p>Your data is used to:</p>
        <ul>
          <li>Provide personalized conversational support.</li>
          <li>Monitor progress and offer feedback.</li>
          <li>Conduct research and development to enhance SerenAI.</li>
          <li>For marketing and promotional purposes.</li>
          <li>Improve services through data analytics and customer support.</li>
        </ul>
        <p>OpenAI may use your data for:</p>
        <ul>
          <li>Operations with affiliates and subsidiaries.</li>
          <li>Collaborations with service providers.</li>
          <li>Compliance with legal requirements, if necessary.</li>
        </ul>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>Data Security</h2>
        <p>
          We implement robust security measures to protect your data, including:
        </p>
        <ul>
          <li>Encryption to secure your data.</li>
          <li>Access controls to prevent unauthorized access.</li>
          <li>Regular security monitoring.</li>
        </ul>
        <p>
          OpenAI also employs security measures for data protection. Learn more
          about their practices{" "}
          <a
            href="https://openai.com/security"
            target="_blank"
            rel="noopener noreferrer"
          >
            here
          </a>
          .
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>Data Retention</h2>
        <p>
          We retain your data only as long as necessary to provide the SerenAI
          service or as required by law. OpenAI may retain data longer based on
          their retention policies.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>Your Rights</h2>
        <p>You have the following rights regarding your data:</p>
        <ul>
          <li>Access</li>
          <li>Correction</li>
          <li>Deletion</li>
          <li>Restriction of processing</li>
          <li>Objection to processing</li>
          <li>Data portability</li>
        </ul>
        <p>
          To exercise these rights, contact us at{" "}
          <a href="mailto:hello@serenai.in">hello@serenai.in</a> or OpenAI at{" "}
          <a
            href="https://help.openai.com/en/"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://help.openai.com/en/
          </a>
          .
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>
          International Data Transfers
        </h2>
        <p>
          SerenAI is operated from India but may transfer data to other
          countries, including the United States where OpenAI is based. We
          ensure that your data remains protected in accordance with this
          Privacy Policy and Indian data protection laws.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>
          Changes to This Privacy Policy
        </h2>
        <p>
          We may update this Privacy Policy periodically. Changes will be posted
          on our website, and we encourage you to review it regularly.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>Contact Us</h2>
        <p>For any questions about this Privacy Policy, contact us at:</p>
        <p>
          SerenAI
          <br />
          2nd Floor, B156, Sector 55
          <br />
          Noida, Uttar Pradesh, 201301
          <br />
          <a href="mailto:hello@serenai.in">hello@serenai.in</a>
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>Disclaimer</h2>
        <p>
          The services provided by SerenAI do not replace professional medical
          advice. Always consult a qualified healthcare provider for any mental
          health concerns.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>
          Limitation of Liability
        </h2>
        <p>
          SerenAI and its affiliates are not liable for damages related to the
          use of our service, including direct, indirect, incidental,
          consequential, or punitive damages.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>User Conduct</h2>
        <p>
          You agree to use SerenAI only for lawful purposes and in accordance
          with this Privacy Policy. Do not use the service for harmful,
          offensive, or illegal activities.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>Termination</h2>
        <p>
          SerenAI may terminate access to the service for code of conduct
          violations or other appropriate reasons, without notice.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>Governing Law</h2>
        <p>This Privacy Policy is governed by the laws of India.</p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>Dispute Resolution</h2>
        <p>
          Disputes will be resolved by binding arbitration in New Delhi, India,
          in accordance with the Arbitration and Conciliation Act, 1996, and
          conducted in English.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>Entire Agreement</h2>
        <p>
          This Privacy Policy is the entire agreement regarding your use of the
          SerenAI service and supersedes any prior communications or agreements.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>Severability</h2>
        <p>
          If any part of this Privacy Policy is deemed invalid or unenforceable,
          the remaining provisions will remain in effect.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>Notices</h2>
        <p>
          Notices must be in writing and sent by hand, certified mail, or
          overnight courier to the addresses provided above.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>Amendments</h2>
        <p>
          We may amend this Privacy Policy by posting the updated version on our
          website. Changes are effective immediately upon posting.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>
          Data Protection Officer
        </h2>
        <p>
          In compliance with the Digital Personal Data Protection Act, 2023,
          SerenAI has a Data Protection Officer overseeing compliance. Contact
          the DPO at <a href="mailto:help@serenai.in">help@serenai.in</a>.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>
          Cross-border Data Transfers
        </h2>
        <p>
          SerenAI may transfer data to OpenAI in the United States, which
          adheres to the EU-US and Swiss-US Privacy Shield Frameworks.
        </p>
      </section>
      <br />
      <br />
    </div>
  );
};

export default PrivacyPolicy;
