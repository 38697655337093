import React from "react";
import "./TermsConditions.css";

const TermsAndConditions = () => {
  return (
    <div className="terms-container container">
      <center>
        <h1 style={{ marginBottom: 10, marginTop: 20 }}>Terms & Conditions</h1>
      </center>
      <br />
      <br />

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>User Conduct</h2>
        <h3 style={{ marginBottom: 10, marginTop: 20 }}>
          1.1 Acceptable Behavior
        </h3>
        <p>
          We ask that all users engage respectfully and lawfully while using the
          SerenAI platform. Harassment, abuse, or misuse of the service is
          strictly prohibited.
        </p>

        <h3 style={{ marginBottom: 10, marginTop: 20 }}>1.2 Code of Conduct</h3>
        <p>
          SerenAI has a code of conduct to ensure a positive and supportive
          environment. All users must follow this code to maintain a friendly
          and respectful space for everyone.
        </p>

        <h3 style={{ marginBottom: 10, marginTop: 20 }}>
          1.3 Consequences for Violation
        </h3>
        <p>
          If you violate the code of conduct, you may face consequences such as
          temporary suspension or permanent termination of access to SerenAI.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>
          Disclaimer of Professional Therapy Services
        </h2>
        <h3 style={{ marginBottom: 10, marginTop: 20 }}>
          2.1 Not a Substitute for Professional Therapy
        </h3>
        <p>
          SerenAI and its AI technology are not a replacement for professional
          therapy or licensed companionship services. We do not provide therapy
          or companionship from licensed professionals.
        </p>

        <h3 style={{ marginBottom: 10, marginTop: 20 }}>
          2.2 Data-Based AI Technology
        </h3>
        <p>
          Our AI technology is based on aggregated data relevant to
          companionship and support but does not replace professional services.
        </p>

        <h3 style={{ marginBottom: 10, marginTop: 20 }}>
          2.3 Service Limitations
        </h3>
        <p>
          As mentioned, our AI provides support based on data and is not a
          substitute for professional services. Any violation of the code of
          conduct could result in consequences, including suspension or
          termination.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>Data Privacy</h2>
        <h3 style={{ marginBottom: 10, marginTop: 20 }}>3.1 Transparency</h3>
        <p>
          We are committed to being transparent about how we handle your data.
          Please review our Privacy Policy to understand how we collect, use,
          and protect your personal information.
        </p>

        <h3 style={{ marginBottom: 10, marginTop: 20 }}>
          3.2 Consent for Data Usage
        </h3>
        <p>
          Our AI technology uses aggregated data to offer support, and by using
          SerenAI, you consent to this use of data.
        </p>

        <h3 style={{ marginBottom: 10, marginTop: 20 }}>
          3.3 Security Measures
        </h3>
        <p>
          SerenAI implements industry-standard security measures to protect your
          data. However, please be aware that no online platform can guarantee
          complete security.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>
          Limitation of Liability
        </h2>
        <h3 style={{ marginBottom: 10, marginTop: 20 }}>
          4.1 Service Interruptions
        </h3>
        <p>
          SerenAI may experience interruptions or technical issues beyond our
          control. We strive to resolve these issues promptly but cannot be held
          liable for them.
        </p>

        <h3 style={{ marginBottom: 10, marginTop: 20 }}>4.2 “As Is” Basis</h3>
        <p>
          SerenAI is provided on an “as is” basis. While we aim for accuracy and
          reliability, we do not offer warranties regarding the completeness or
          accuracy of the information provided.
        </p>

        <h3 style={{ marginBottom: 10, marginTop: 20 }}>
          4.3 User Responsibility
        </h3>
        <p>
          You are responsible for your interactions and use of the platform.
          SerenAI is not liable for any outcomes resulting from your actions or
          decisions based on the AI's support.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>Code of Conduct</h2>
        <h3 style={{ marginBottom: 10, marginTop: 20 }}>
          5.1 Code Establishment
        </h3>
        <p>
          SerenAI has established a code of conduct to ensure a respectful
          environment. This code outlines expected behaviors and interactions on
          the platform.
        </p>

        <h3 style={{ marginBottom: 10, marginTop: 20 }}>
          5.2 Adherence Required
        </h3>
        <p>
          Users must follow the code of conduct, avoiding any behavior that
          disrupts respect, understanding, or support within the platform.
        </p>

        <h3 style={{ marginBottom: 10, marginTop: 20 }}>
          5.3 Consequences for Violation
        </h3>
        <p>
          Violations of the code of conduct may lead to warnings, temporary
          suspension, or permanent termination of access to SerenAI. These
          measures ensure a safe and supportive space for everyone.
        </p>
      </section>

      <section>
        <h2 style={{ marginBottom: 10, marginTop: 20 }}>
          Compliance with Local Laws
        </h2>
        <h3 style={{ marginBottom: 10, marginTop: 20 }}>
          6.1 User Responsibility
        </h3>
        <p>
          Users must comply with all applicable local laws and regulations. It
          is your responsibility to ensure your use of the platform meets the
          legal requirements of your area.
        </p>

        <h3 style={{ marginBottom: 10, marginTop: 20 }}>
          6.2 Legal Consultation
        </h3>
        <p>
          We recommend consulting legal professionals in your jurisdiction to
          ensure compliance with all relevant laws, including those related to
          online services, data protection, and mental health support.
        </p>

        <h3 style={{ marginBottom: 10, marginTop: 20 }}>
          6.3 International Users
        </h3>
        <p>
          If you are outside of India, please note that SerenAI is developed in
          India. You are responsible for understanding and adhering to the laws
          and regulations relevant to your location.
        </p>
      </section>
      <br />
      <br />
    </div>
  );
};

export default TermsAndConditions;
