import React from "react";
import "./TopBar.css";
import { CgMenuRight } from "react-icons/cg";
import { IoClose } from "react-icons/io5";

const TopBar = () => {
  window.onscroll = () => {
    if (
      document.body.scrollTop > 55 ||
      document.documentElement.scrollTop > 55
    ) {
      document.getElementById("nav-bg").classList.add("nav-colored");
    } else {
      document.getElementById("nav-bg").classList.remove("nav-colored");
    }

    var y = window.pageYOffset;

    if (y > 0 && y < 700) {
      getStyle(0);
    } else if (y > 700 && y < 1400) {
      getStyle(1);
    } else if (y > 1400 && y < 2100) {
      getStyle(2);
    } else if (y > 1900 && y < 3400) {
      getStyle(3);
    } else if (y > 3400) {
      getStyle(4);
    }
  };
  const showNavBar = () => {
    var classListOfNav = document.getElementById("nav").classList;
    if (!classListOfNav.contains("responsive_nav")) {
      classListOfNav.add("responsive_nav");
    } else {
      classListOfNav.remove("responsive_nav");
    }
  };

  const [prevTab, setPrevTab] = React.useState(0);

  function getStyle(link_no) {
    document
      .getElementsByClassName("nav-link")
      [prevTab].classList.remove("clicked");
    document
      .getElementsByClassName("nav-link")
      [link_no].classList.add("clicked");
    setPrevTab(link_no);
  }

  return (
    <div id="nav-bg">
      <div className="container topbar_container">
        <h2 style={{ color: "white", fontWeight: "550" }}>
          Seren<span style={{ color: "#7cedf4" }}>AI</span>
        </h2>
        <nav id="nav" onClick={showNavBar}>
          <a
            className="nav-link clicked"
            id="home-btn"
            onClick={getStyle.bind(this, 0)}
            href="#home"
          >
            Home
          </a>
          <a
            className="nav-link"
            id="about-btn"
            onClick={getStyle.bind(this, 1)}
            href="#about"
          >
            About
          </a>
          <a
            className="nav-link"
            id="skills-btn"
            onClick={getStyle.bind(this, 2)}
            href="#skills"
          >
            How Seren Works?
          </a>
          <a
            className="nav-link"
            id="projects-btn"
            onClick={getStyle.bind(this, 3)}
            href="#contact"
          >
            Contact Us
          </a>
          <a
            className="nav-link"
            id="contact-btn"
            onClick={getStyle.bind(this, 4)}
            download={true}
            href="https://apks-serenai.s3.ap-south-1.amazonaws.com/SerenAI_EarlyAcesss_v1.1.0.apk"
          >
            Download
          </a>
          <button className="nav-btn nav-close">
            <IoClose size={25} />
          </button>
        </nav>
        <button className="nav-btn" onClick={showNavBar}>
          <CgMenuRight size={25} />
        </button>
      </div>
    </div>
  );
};

export default TopBar;
