import React from "react";
import "./Header.css";
import { FiMail, FiSend } from "react-icons/fi";
import { FiDownload } from "react-icons/fi";
import { BsGithub, BsGooglePlay, BsInstagram } from "react-icons/bs";
import { BsLinkedin } from "react-icons/bs";
import TypeWriter from "typewriter-effect";

const Header = () => {
  return (
    <div id="home" className="container header_container">
      <div className="intro-wrapper">
        <div className="social-icons">
          <span></span>
          <a href="mailto:hello@serenai.in" target="blank">
            <FiMail size={20} />
          </a>
          <a
            href="https://play.google.com/store/apps/dev?id=6458947373351124430&hl=en"
            target="blank"
          >
            <BsGooglePlay size={20} />
          </a>
          <a href="https://www.instagram.com/ser_en.ai/?hl=en" target="blank">
            <BsInstagram size={20} />
          </a>
          <span></span>
        </div>

        <div className="intro">
          <h3 style={{ lineHeight: 1.1, marginBottom: 12 }}>
            Find Serenity,Embrace Enlightenment.
          </h3>
          <h1 id="typer">
            <TypeWriter
              onInit={(type) => {
                type
                  .typeString("I’m Seren,<br/>")
                  .pauseFor(50)
                  .typeString("<span>Your Mental Health<br/>Companion.</span>")
                  .start();
              }}
            />
          </h1>
          <div className="btns">
            <a
              download={true}
              href="https://apks-serenai.s3.ap-south-1.amazonaws.com/SerenAI_EarlyAcesss_v1.1.0.apk"
            >
              <button className="white-btn">
                Try Now
                <FiDownload />
              </button>
            </a>
            <a href="mailto:hello@serenai.in">
              <button className="accent-btn">
                Say Hello
                <FiSend />
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="dp"></div>
    </div>
  );
};

export default Header;
