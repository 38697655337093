import React from "react";
import "./Projects.css";
import { FiDownload } from "react-icons/fi";
import { BsGithub } from "react-icons/bs";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ProjectCompo = (props) => {
  let items = [
    "s6.jpg",
    "s2.jpg",
    "s4.jpg",
    "s1.jpg",
    "s5.jpg",
    "s8.jpg",
    "s3.jpg",
    "s7.jpg",
  ];
  return (
    <div className="project-card">
      <div className="individual-project-container">
        {items.map((item, index) => {
          return (
            <LazyLoadImage
              loading="lazy"
              alt={item}
              className="project-img"
              src={`./res/projects/p${props.pid}/${item}`}
            />
          );
        })}
      </div>
      <div id="nothing"></div>
      <div className="project-info">
        <h3 id="project-title">{props.title}</h3>
        <p id="project-desc">{props.desc}</p>
        <div className="btns">
          <a
            target="_blank"
            rel="noopener noreferrer"
            download={true}
            href="https://apks-serenai.s3.ap-south-1.amazonaws.com/SerenAI_EarlyAcesss_v1.1.0.apk"
          >
            <button className="accent-btn">
              Download
              <FiDownload />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};
export default ProjectCompo;
