import React from "react";
import "./Skills.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Skills = () => {
  return (
    <AnimationOnScroll duration={0.4} animateIn="animate__fadeIn">
      <section id="skills" className="container skills_container">
        <h2>How Seren Works?</h2>
        <div className="skill-cards-container">
          <div className="skill-card">
            <div>
              <div className="app-dev-img skill-img"></div>
              <h3 className="subtitle">Fine Tuned AI</h3>
              <p>
                SerenAI is fine-tuned on thousands of conversational therapy
                data points. This training enables it to understand and respond
                empathetically to various mental health issues. The AI delivers
                reliable, therapy-informed support as a mental health companion.
              </p>
            </div>
          </div>
          <div className="skill-card">
            <div>
              <div className="flutter-dev-img skill-img"></div>
              <h3 className="subtitle">24/7 Availability</h3>
              <p>
                SerenAI is available around the clock, providing support
                whenever you need it. This constant availability ensures you
                always have access to mental health resources and guidance.
                Whether day or night, SerenAI is ready to assist you on your
                journey to well-being.
              </p>
            </div>
          </div>
          <div className="skill-card">
            <div>
              <div className="web-dev-img skill-img"></div>
              <h3 className="subtitle">Privacy and Confidentiality</h3>
              <p>
                SerenAI prioritizes your privacy and confidentiality. Advanced
                security measures protect your personal information and ensure
                all interactions remain confidential. You can trust SerenAI to
                provide a safe and secure environment for discussing your mental
                health concerns.
              </p>
            </div>
          </div>
        </div>
      </section>
    </AnimationOnScroll>
  );
};

export default Skills;
