import "./App.css";
import React from "react";
import TopBar from "./Components/TopBar/TopBar";
import Header from "./Components/Header/Header";
import About from "./Components/About/About";
import Skills from "./Components/Skills/Skills";
import Contact from "./Components/Contact/Contact";
import Projects from "./Components/Projects/Projects";
import Footer from "./Components/Footer/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TermsAndConditions from "./Components/TermsConditions/TermsConditions";
import PrivacyPolicy from "./Components/TermsConditions/PrivacyPolicy";

function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <TopBar />
              <div className="bg">
                <Header />
              </div>
              <Projects />
              <About />
              <Skills />
              <Contact />
              <Footer />
            </>
          }
        />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </Router>
  );
}

export default App;
